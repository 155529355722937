import { useRouter } from 'next/router';
import * as React from 'react';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';
import { FullScreenLoader } from 'common/components/FullScreenLoader';
import { RestaurantMain } from 'restaurant/components/RestaurantMain';

const Page = () => {
    const router = useRouter();
    const { restaurantId } = router.query;
    const { t } = useTranslation('restaurant.takeaway');

    if (typeof restaurantId !== 'string') return <FullScreenLoader componentOrShow={false} />;

    return (
        <>
            <Head>
                <title>{t('title')}</title>
                <link href="https://fonts.googleapis.com" rel="preconnect" />
                <link href="https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap" rel="stylesheet" />
            </Head>
            <RestaurantMain page="takeAway" restaurantId={restaurantId} />
        </>
    );
};

export default Page;
